import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_PROJETOS:
    case types.DELETE_PROJETOS:
    case types.CREATE_PROJETO:
    case types.UPDATE_PROJETO:
    case types.UPDATE_PROJETO_TRATAMENTOS:
      return { ...state, loading: true }

    case types.FETCH_PROJETOS_SUCCESS:
    case types.CREATE_PROJETO_SUCCESS:
      return { ...state, projetos: action.payload, loading: false }

    case types.FETCH_PROJETOS_FAIL:
    case types.CREATE_PROJETO_FAIL:
    case types.UPDATE_PROJETO_SUCCESS:
    case types.UPDATE_PROJETO_FAIL:
    case types.DELETE_PROJETO_SUCCESS:
    case types.DELETE_PROJETO_FAIL:
    case types.UPDATE_PROJETO_TRATAMENTOS_SUCCESS:
    case types.UPDATE_PROJETO_TRATAMENTOS_FAIL:
      return { ...state, loading: false }

    case types.FETCH_PROJETO_TRATAMENTOS:
      return { ...state, loadingTratamentos: true, projetoTratamentos: [] }

    case types.FETCH_PROJETO_TRATAMENTOS_SUCCESS:
      return {
        ...state,
        projetoTratamentos: action.payload,
        loadingTratamentos: false
      }

    case types.FETCH_PROJETO_TRATAMENTOS_FAIL:
      return { ...state, loadingTratamentos: false }

    default:
      return state
  }
}
