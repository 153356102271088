export const ActionTypes = {
  FETCH_TRATAMENTOS: 'FETCH_TRATAMENTOS',
  FETCH_TRATAMENTOS_SUCCESS: 'FETCH_TRATAMENTOS_SUCCESS',
  FETCH_TRATAMENTOS_FAIL: 'FETCH_TRATAMENTOS_FAIL',

  CREATE_TRATAMENTO: 'CREATE_TRATAMENTO',
  CREATE_TRATAMENTO_SUCCESS: 'CREATE_TRATAMENTO_SUCCESS',
  CREATE_TRATAMENTO_FAIL: 'CREATE_TRATAMENTO_FAIL',

  DELETE_TRATAMENTO: 'DELETE_TRATAMENTO',
  DELETE_TRATAMENTO_SUCCESS: 'DELETE_TRATAMENTO_SUCCESS',
  DELETE_TRATAMENTO_FAIL: 'DELETE_TRATAMENTO_FAIL',

  UPDATE_TRATAMENTO: 'UPDATE_TRATAMENTO',
  UPDATE_TRATAMENTO_SUCCESS: 'UPDATE_TRATAMENTO_SUCCESS',
  UPDATE_TRATAMENTO_FAIL: 'UPDATE_TRATAMENTO_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  tratamentos: []
}
