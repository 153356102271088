import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import t from 'prop-types'
import { LinearProgress } from '@material-ui/core'

import {
  HOME,
  LOGIN,
  CARACTERISTICAS,
  EVENTOS,
  MODIFICACOES,
  PORTAS_ENXERTO,
  VARIEDADES,
  TRATAMENTOS,
  ACOES,
  USUARIOS,
  PROJETOS,
  PLANTAS,
  PROJETO,
  EXPORT
} from './routes'

// const MainPage = lazy(() => import('pages/main'))
const Login = lazy(() => import('pages/login'))
const Caracteristicas = lazy(() => import('pages/caracteristicas'))
const Eventos = lazy(() => import('pages/eventos'))
const Modificacoes = lazy(() => import('pages/modificacoes'))
const PortasEnxerto = lazy(() => import('pages/portas-enxerto'))
const Variedades = lazy(() => import('pages/variedades'))
const Tratamentos = lazy(() => import('pages/tratamentos'))
const Acoes = lazy(() => import('pages/acoes'))
const Usuarios = lazy(() => import('pages/usuarios'))
const Projetos = lazy(() => import('pages/projetos'))
const Plantas = lazy(() => import('pages/plantas'))
const Exportation = lazy(() => import('pages/export'))

const App = () => {
  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem('token') !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: LOGIN }}
          />
        )
      }}
    />
  )

  const NotAuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem('token') === null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: HOME }} />
        )
      }}
    />
  )

  const RedirectHome = ({ ...rest }) => (
    <Route {...rest} render={() => <Redirect from='/' to={HOME} />} />
  )

  NotAuthenticatedRoute.propTypes = {
    component: t.object
  }

  AuthenticatedRoute.propTypes = {
    component: t.object
  }

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <NotAuthenticatedRoute path={LOGIN} component={Login} />

        {/* <AuthenticatedRoute exact path={HOME} component={MainPage} /> */}
        <AuthenticatedRoute exact path={CARACTERISTICAS} component={Caracteristicas} />
        <AuthenticatedRoute exact path={EVENTOS} component={Eventos} />
        <AuthenticatedRoute exact path={MODIFICACOES} component={Modificacoes} />
        <AuthenticatedRoute exact path={PORTAS_ENXERTO} component={PortasEnxerto} />
        <AuthenticatedRoute exact path={VARIEDADES} component={Variedades} />
        <AuthenticatedRoute exact path={TRATAMENTOS} component={Tratamentos} />
        <AuthenticatedRoute exact path={ACOES} component={Acoes} />
        <AuthenticatedRoute exact path={USUARIOS} component={Usuarios} />
        <AuthenticatedRoute exact path={PROJETOS} component={Projetos} />
        <AuthenticatedRoute exact path={`${PROJETO}${PLANTAS}`} component={Plantas} />
        <AuthenticatedRoute exact path={`${PROJETO}${EXPORT}`} component={Exportation} />

        <AuthenticatedRoute path='/' component={RedirectHome} />

      </Switch>
    </Suspense>
  )
}

export default App
