import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_USUARIOS:
    case types.CREATE_USUARIOS:
    case types.DELETE_USUARIOS:
      return { ...state, loading: true }

    case types.CREATE_USUARIOS_SUCCESS:
    case types.FETCH_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        usuarios: action.payload
      }

    case types.FETCH_USUARIOS_FAIL:
    case types.CREATE_USUARIOS_FAIL:
    case types.DELETE_USUARIOS_FAIL:
    case types.DELETE_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case types.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}
