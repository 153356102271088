import { ActionTypes as types, INITIAL_STATE } from './constants'

function mountObject (payload) {
  return {
    id: payload.coluna,
    fkProjeto: payload.fkProjeto,
    fkTratamento: payload.fkTratamento,
    tratamento: payload.tratamento,
    cor: payload.fkAcao === 1 ? '#000' : payload.cor,
    idPlanta: payload.idPlanta,
    coluna: payload.coluna,
    linha: payload.linha,
    fkAcao: payload.fkAcao
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_PLANTAS:
      return {
        ...state,
        loading: false
      }

    case types.FETCH_PLANTAS_SUCCESS: {
      let array = []
      const payload = action.payload
      for (let i = 0; i <= payload.length - 1; i++) {
        let place = array.find(item => item.id === payload[i].linha)
        if (place) {
          place = {
            id: place.id,
            columns: [...place.columns, mountObject(payload[i])]
          }
          array.splice(-1, 1)
          array = [...array, place]
        } else {
          array.push({
            id: payload[i].linha,
            columns: [mountObject(payload[i])]
          })
        }
      }

      return {
        ...state,
        loading: false,
        plantas: array
      }
    }

    case types.UPDATE_PLANTS_ACTIONS: {
      const ids = action.payload.ids
      const isErradicacao = action.payload.isErradicacao
      let plants = state.plantas
      if (isErradicacao) {
        plants = plants.map(plant => {
          const columns = plant.columns.map(item => {
            if (ids.indexOf(item.idPlanta) !== -1) {
              return mountObject({ ...item, fkAcao: 1 })
            }
            return mountObject(item)
          })
          return {
            id: plant.id,
            columns: columns
          }
        })
      }
      return {
        ...state,
        plantas: plants,
        loading: false,
        loadingData: {
          ...state.loadingData,
          action: false
        }
      }
    }

    case types.FETCH_PLANTAS_FAIL:
    case types.ADD_TREATMENT_FAIL:
    case types.CREATE_CROCKI_FAIL:
      return {
        ...state,
        loading: false
      }

    case 'RESET_STATE':
      return INITIAL_STATE

    case 'ADD_TREATAMENT':
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          tratament: true
        }
      }

    case 'ADD_TREATAMENT_SUCCESS':
    case 'ADD_TREATAMENT_FAIL':
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          tratament: false
        }
      }

    case 'UPDATE_PLANTS_ACTION_FAIL':
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          action: false
        }
      }

    case 'ADD_ACTION':
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          action: true
        }
      }

    default:
      return state
  }
}
