export const ActionTypes = {
  FETCH_TRATAMENTOS: 'FETCH_TRATAMENTOS',
  FETCH_TRATAMENTOS_SUCCESS: 'FETCH_TRATAMENTOS_SUCCESS',
  FETCH_TRATAMENTOS_FAIL: 'FETCH_TRATAMENTOS_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  tratamentos: []
}
