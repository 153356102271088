export const PROJETOS = '/projetos'
export const BAR = '/'
export const HOME = PROJETOS
export const LOGIN = '/login'
export const TESTE = '/teste'
export const CARACTERISTICAS = '/caracteristicas'
export const EVENTOS = '/plantas'
export const MODIFICACOES = '/construcoes'
export const PORTAS_ENXERTO = '/porta-enxertos'
export const VARIEDADES = '/variedades'
export const TRATAMENTOS = '/tratamentos'
export const ACOES = '/acoes'
export const USUARIOS = '/usuarios'
export const PROJETO = '/projeto'
export const PLANTAS = '/plantas'
export const EXPORT = '/export'

export const API = {
  BASE: 'https://biotecnologia.fundecitrus.com.br/api',
  LOGIN: 'auth/login/',
  CARACTERISTICAS: 'caracteristicas/',
  EVENTOS: 'eventos/',
  MODIFICACOES: 'modificacoes/',
  PORTAS_ENXERTO: 'portas-enxerto/',
  VARIEDADES: 'variedades/',
  TRATAMENTOS: 'tratamentos/',
  ACOES: 'acoes/',
  USUARIOS: 'usuarios/',
  PROJETOS: 'projetos/',
  PLANTAS: 'plantas/',
  CROCKI: 'crocki/',
  INICIAR: 'iniciar/',
  LIST_TRATAMENTOS: 'listar-tratamentos/',
  EXPORTAR: 'exportar/'
}
