export const ActionTypes = {
  FETCH_PLANTAS: 'FETCH_PLANTAS',
  FETCH_PLANTAS_SUCCESS: 'FETCH_PLANTAS_SUCCESS',
  FETCH_PLANTAS_FAIL: 'FETCH_PLANTAS_FAIL',

  ADD_TREATMENT: 'ADD_TREATMENT',
  ADD_TREATMENT_SUCCESS: 'ADD_TREATMENT_SUCCESS',
  ADD_TREATMENT_FAIL: 'ADD_TREATMENT_FAIL',

  CREATE_CROCKI: 'CREATE_CROCKI',
  CREATE_CROCKI_SUCCESS: 'CREATE_CROCKI_SUCCESS',
  CREATE_CROCKI_FAIL: 'CREATE_CROCKI_FAIL',

  FETCH_ACTIONS_PLANT: 'FETCH_ACTIONS_PLANT',
  FETCH_ACTIONS_PLANT_SUCCESS: 'FETCH_ACTIONS_PLANT_SUCCESS',
  FETCH_ACTIONS_PLANT_FAIL: 'FETCH_ACTIONS_PLANT_FAIL',

  UPDATE_PLANTS_ACTIONS: 'UPDATE_PLANTS_ACTIONS'
}

export const INITIAL_STATE = {
  loading: false,
  plantas: [],
  loadingData: {
    action: false,
    tratament: false
  }
}
