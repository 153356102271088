export const ActionTypes = {
  FETCH_PROJETOS: 'FETCH_PROJETOS',
  FETCH_PROJETOS_SUCCESS: 'FETCH_PROJETOS_SUCCESS',
  FETCH_PROJETOS_FAIL: 'FETCH_PROJETOS_FAIL',

  CREATE_PROJETO: 'CREATE_PROJETO',
  CREATE_PROJETO_SUCCESS: 'CREATE_PROJETO_SUCCESS',
  CREATE_PROJETO_FAIL: 'CREATE_PROJETO_FAIL',

  DELETE_PROJETO: 'DELETE_PROJETO',
  DELETE_PROJETO_SUCCESS: 'DELETE_PROJETO_SUCCESS',
  DELETE_PROJETO_FAIL: 'DELETE_PROJETO_FAIL',

  UPDATE_PROJETO: 'UPDATE_PROJETO',
  UPDATE_PROJETO_SUCCESS: 'UPDATE_PROJETO_SUCCESS',
  UPDATE_PROJETO_FAIL: 'UPDATE_PROJETO_FAIL',

  FETCH_PROJETO_TRATAMENTOS: 'FETCH_PROJETO_TRATAMENTOS',
  FETCH_PROJETO_TRATAMENTOS_SUCCESS: 'FETCH_PROJETO_TRATAMENTOS_SUCCESS',
  FETCH_PROJETO_TRATAMENTOS_FAIL: 'FETCH_PROJETO_TRATAMENTOS_FAIL',

  UPDATE_PROJETO_TRATAMENTOS: 'UPDATE_PROJETO_TRATAMENTOS',
  UPDATE_PROJETO_TRATAMENTOS_SUCCESS: 'UPDATE_PROJETO_TRATAMENTOS_SUCCESS',
  UPDATE_PROJETO_TRATAMENTOS_FAIL: 'UPDATE_PROJETO_TRATAMENTOS_FAIL',

  DELETE_PROJETO_TRATAMENTOS: 'DELETE_PROJETO_TRATAMENTOS',
  DELETE_PROJETO_TRATAMENTOS_SUCCESS: 'DELETE_PROJETO_TRATAMENTOS_SUCCESS',
  DELETE_PROJETO_TRATAMENTOS_FAIL: 'DELETE_PROJETO_TRATAMENTOS_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  projetos: [],
  tratamentos: []
}
