import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_TRATAMENTOS:
      return { ...state, loading: true }

    case types.FETCH_TRATAMENTOS_SUCCESS:
      return { ...state, tratamentos: action.payload, loading: false }

    case types.FETCH_TRATAMENTOS_FAIL:
      return { ...state, loading: false }

    default:
      return state
  }
}
