import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_EVENTOS:
    case types.CREATE_EVENTOS:
    case types.DELETE_EVENTOS:
      return { ...state, loading: true }

    case types.CREATE_EVENTOS_SUCCESS:
    case types.FETCH_EVENTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventos: action.payload
      }

    case types.FETCH_EVENTOS_FAIL:
    case types.CREATE_EVENTOS_FAIL:
    case types.DELETE_EVENTOS_FAIL:
    case types.DELETE_EVENTOS_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case types.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}
