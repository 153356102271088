import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_PORTAS_ENXERTO:
    case types.CREATE_PORTAS_ENXERTO:
    case types.DELETE_PORTAS_ENXERTO:
      return { ...state, loading: true }

    case types.CREATE_PORTAS_ENXERTO_SUCCESS:
    case types.FETCH_PORTAS_ENXERTO_SUCCESS:
      return {
        ...state,
        loading: false,
        portasEnxerto: action.payload || []
      }

    case types.FETCH_PORTAS_ENXERTO_FAIL:
    case types.CREATE_PORTAS_ENXERTO_FAIL:
    case types.DELETE_PORTAS_ENXERTO_FAIL:
    case types.DELETE_PORTAS_ENXERTO_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case types.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}
