export const ActionTypes = {
  FETCH_VARIEDADES: 'FETCH_VARIEDADES',
  FETCH_VARIEDADES_SUCCESS: 'FETCH_VARIEDADES_SUCCESS',
  FETCH_VARIEDADES_FAIL: 'FETCH_VARIEDADES_FAIL',

  CREATE_VARIEDADES: 'CREATE_VARIEDADES',
  CREATE_VARIEDADES_SUCCESS: 'CREATE_VARIEDADES_SUCCESS',
  CREATE_VARIEDADES_FAIL: 'CREATE_VARIEDADES_FAIL',

  DELETE_VARIEDADES: 'DELETE_VARIEDADES',
  DELETE_VARIEDADES_SUCCESS: 'DELETE_VARIEDADES_SUCCESS',
  DELETE_VARIEDADES_FAIL: 'DELETE_VARIEDADES_FAIL',

  UPDATE_VARIEDADES: 'UPDATE_VARIEDADES',
  UPDATE_VARIEDADES_SUCCESS: 'UPDATE_VARIEDADES_SUCCESS',
  UPDATE_VARIEDADES_FAIL: 'UPDATE_VARIEDADES_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  variedades: []
}
