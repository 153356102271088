import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_TRATAMENTOS:
    case types.DELETE_TRATAMENTOS:
    case types.CREATE_TRATAMENTO:
    case types.UPDATE_TRATAMENTO:
      return { ...state, loading: true }

    case types.FETCH_TRATAMENTOS_SUCCESS:
    case types.CREATE_TRATAMENTO_SUCCESS:
      return { ...state, tratamentos: action.payload, loading: false }

    case types.DELETE_TRATAMENTO_SUCCESS:
    case types.DELETE_TRATAMENTO_FAIL:
    case types.FETCH_TRATAMENTOS_FAIL:
    case types.CREATE_TRATAMENTO_FAIL:
    case types.UPDATE_TRATAMENTO_SUCCESS:
    case types.UPDATE_TRATAMENTO_FAIL:
      return { ...state, loading: false }

    default:
      return state
  }
}
