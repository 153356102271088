export const ActionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  error: false,
  user: {
    idUsuario: 1,
    nome: '',
    email: '',
    senha: '',
    perfil: '',
    fkPerfil: 1,
    ativo: true
  }
}
