export const ActionTypes = {
  FETCH_EVENTOS: 'FETCH_EVENTOS',
  FETCH_EVENTOS_SUCCESS: 'FETCH_EVENTOS_SUCCESS',
  FETCH_EVENTOS_FAIL: 'FETCH_EVENTOS_FAIL',

  CREATE_EVENTOS: 'CREATE_EVENTOS',
  CREATE_EVENTOS_SUCCESS: 'CREATE_EVENTOS_SUCCESS',
  CREATE_EVENTOS_FAIL: 'CREATE_EVENTOS_FAIL',

  DELETE_EVENTOS: 'DELETE_EVENTOS',
  DELETE_EVENTOS_SUCCESS: 'DELETE_EVENTOS_SUCCESS',
  DELETE_EVENTOS_FAIL: 'DELETE_EVENTOS_FAIL',

  UPDATE_EVENTOS: 'UPDATE_EVENTOS',
  UPDATE_EVENTOS_SUCCESS: 'UPDATE_EVENTOS_SUCCESS',
  UPDATE_EVENTOS_FAIL: 'UPDATE_EVENTOS_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  eventos: []
}
