export const ActionTypes = {
  FETCH_MODIFICACOES: 'FETCH_MODIFICACOES',
  FETCH_MODIFICACOES_SUCCESS: 'FETCH_MODIFICACOES_SUCCESS',
  FETCH_MODIFICACOES_FAIL: 'FETCH_MODIFICACOES_FAIL',

  CREATE_MODIFICACOES: 'CREATE_MODIFICACOES',
  CREATE_MODIFICACOES_SUCCESS: 'CREATE_MODIFICACOES_SUCCESS',
  CREATE_MODIFICACOES_FAIL: 'CREATE_MODIFICACOES_FAIL',

  DELETE_MODIFICACOES: 'DELETE_MODIFICACOES',
  DELETE_MODIFICACOES_SUCCESS: 'DELETE_MODIFICACOES_SUCCESS',
  DELETE_MODIFICACOES_FAIL: 'DELETE_MODIFICACOES_FAIL',

  UPDATE_MODIFICACOES: 'UPDATE_MODIFICACOES',
  UPDATE_MODIFICACOES_SUCCESS: 'UPDATE_MODIFICACOES_SUCCESS',
  UPDATE_MODIFICACOES_FAIL: 'UPDATE_MODIFICACOES_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  modificacoes: []
}
