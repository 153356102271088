export const ActionTypes = {
  FETCH_USUARIOS: 'FETCH_USUARIOS',
  FETCH_USUARIOS_SUCCESS: 'FETCH_USUARIOS_SUCCESS',
  FETCH_USUARIOS_FAIL: 'FETCH_USUARIOS_FAIL',

  CREATE_USUARIOS: 'CREATE_USUARIOS',
  CREATE_USUARIOS_SUCCESS: 'CREATE_USUARIOS_SUCCESS',
  CREATE_USUARIOS_FAIL: 'CREATE_USUARIOS_FAIL',

  DELETE_USUARIOS: 'DELETE_USUARIOS',
  DELETE_USUARIOS_SUCCESS: 'DELETE_USUARIOS_SUCCESS',
  DELETE_USUARIOS_FAIL: 'DELETE_USUARIOS_FAIL',

  UPDATE_USUARIOS: 'UPDATE_USUARIOS',
  UPDATE_USUARIOS_SUCCESS: 'UPDATE_USUARIOS_SUCCESS',
  UPDATE_USUARIOS_FAIL: 'UPDATE_USUARIOS_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  usuarios: []
}
