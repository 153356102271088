export const ActionTypes = {
  FETCH_CARACTERISTICAS: 'FETCH_CARACTERISTICAS',
  FETCH_CARACTERISTICAS_SUCCESS: 'FETCH_CARACTERISTICAS_SUCCESS',
  FETCH_CARACTERISTICAS_FAIL: 'FETCH_CARACTERISTICAS_FAIL',

  CREATE_CARACTERISTICAS: 'CREATE_CARACTERISTICAS',
  CREATE_CARACTERISTICAS_SUCCESS: 'CREATE_CARACTERISTICAS_SUCCESS',
  CREATE_CARACTERISTICAS_FAIL: 'CREATE_CARACTERISTICAS_FAIL',

  DELETE_CARACTERISTICAS: 'DELETE_CARACTERISTICAS',
  DELETE_CARACTERISTICAS_SUCCESS: 'DELETE_CARACTERISTICAS_SUCCESS',
  DELETE_CARACTERISTICAS_FAIL: 'DELETE_CARACTERISTICAS_FAIL',

  UPDATE_CARACTERISTICAS: 'UPDATE_CARACTERISTICAS',
  UPDATE_CARACTERISTICAS_SUCCESS: 'UPDATE_CARACTERISTICAS_SUCCESS',
  UPDATE_CARACTERISTICAS_FAIL: 'UPDATE_CARACTERISTICAS_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  caracteristicas: []
}
