export const ActionTypes = {
  FETCH_ACOES: 'FETCH_ACOES',
  FETCH_ACOES_SUCCESS: 'FETCH_ACOES_SUCCESS',
  FETCH_ACOES_FAIL: 'FETCH_ACOES_FAIL',

  CREATE_ACOES: 'CREATE_ACOES',
  CREATE_ACOES_SUCCESS: 'CREATE_ACOES_SUCCESS',
  CREATE_ACOES_FAIL: 'CREATE_ACOES_FAIL',

  DELETE_ACOES: 'DELETE_ACOES',
  DELETE_ACOES_SUCCESS: 'DELETE_ACOES_SUCCESS',
  DELETE_ACOES_FAIL: 'DELETE_ACOES_FAIL',

  UPDATE_ACOES: 'UPDATE_ACOES',
  UPDATE_ACOES_SUCCESS: 'UPDATE_ACOES_SUCCESS',
  UPDATE_ACOES_FAIL: 'UPDATE_ACOES_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  acoes: []
}
