export const ActionTypes = {
  FETCH_PORTAS_ENXERTO: 'FETCH_PORTAS_ENXERTO',
  FETCH_PORTAS_ENXERTO_SUCCESS: 'FETCH_PORTAS_ENXERTO_SUCCESS',
  FETCH_PORTAS_ENXERTO_FAIL: 'FETCH_PORTAS_ENXERTO_FAIL',

  CREATE_PORTAS_ENXERTO: 'CREATE_PORTAS_ENXERTO',
  CREATE_PORTAS_ENXERTO_SUCCESS: 'CREATE_PORTAS_ENXERTO_SUCCESS',
  CREATE_PORTAS_ENXERTO_FAIL: 'CREATE_PORTAS_ENXERTO_FAIL',

  DELETE_PORTAS_ENXERTO: 'DELETE_PORTAS_ENXERTO',
  DELETE_PORTAS_ENXERTO_SUCCESS: 'DELETE_PORTAS_ENXERTO_SUCCESS',
  DELETE_PORTAS_ENXERTO_FAIL: 'DELETE_PORTAS_ENXERTO_FAIL',

  UPDATE_PORTAS_ENXERTO: 'UPDATE_PORTAS_ENXERTO',
  UPDATE_PORTAS_ENXERTO_SUCCESS: 'UPDATE_PORTAS_ENXERTO_SUCCESS',
  UPDATE_PORTAS_ENXERTO_FAIL: 'UPDATE_PORTAS_ENXERTO_FAIL',

  RESET_STATE: 'RESET_STATE'
}

export const INITIAL_STATE = {
  loading: false,
  portasEnxerto: []
}
