import { combineReducers } from 'redux'

import acoes from 'pages/acoes/reducers'
import main from 'pages/main/reducers'
import login from 'pages/login/reducers'
import caracteristicas from 'pages/caracteristicas/reducers'
import eventos from 'pages/eventos/reducers'
import modificacoes from 'pages/modificacoes/reducers'
import portasEnxerto from 'pages/portas-enxerto/reducers'
import tratamentos from 'pages/tratamentos/reducers'
import variedades from 'pages/variedades/reducers'
import usuarios from 'pages/usuarios/reducers'
import projetos from 'pages/projetos/reducers'
import plantas from 'pages/plantas/reducers'
import exportation from 'pages/export/reducers'

export default combineReducers({
  acoes,
  main,
  login,
  caracteristicas,
  eventos,
  modificacoes,
  portasEnxerto,
  tratamentos,
  variedades,
  usuarios,
  projetos,
  plantas,
  exportation
})
