import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN:
      return { ...state, loading: true }

    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token)

      return {
        ...state,
        loading: false,
        user: action.payload.user
      }

    case types.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      }

    case types.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}
