import { ActionTypes as types, INITIAL_STATE } from './constants'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_MODIFICACOES:
    case types.CREATE_MODIFICACOES:
    case types.DELETE_MODIFICACOES:
      return { ...state, loading: true }

    case types.CREATE_MODIFICACOES_SUCCESS:
    case types.FETCH_MODIFICACOES_SUCCESS:
      return {
        ...state,
        loading: false,
        modificacoes: action.payload || []
      }

    case types.FETCH_MODIFICACOES_FAIL:
    case types.CREATE_MODIFICACOES_FAIL:
    case types.DELETE_MODIFICACOES_FAIL:
    case types.DELETE_MODIFICACOES_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case types.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}
